:root {
  --swiper-theme-color: #dc2626;
}

.lateralSwiper .swiper-slide {
  opacity: 0.4;
}

.lateralSwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 2px solid var(--swiper-theme-color);
  box-sizing: border-box;
  border-radius: 0.375rem;
}
.lateralSwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 2px solid var(--swiper-theme-color);
  box-sizing: border-box;
  border-radius: 0.375rem;
}

.swiper-button-prev {
  transform: rotate(90deg) scale(0.5);
  top: 5%;
  left: 40%;
}

.swiper-button-next {
  transform: rotate(90deg) scale(0.5);
  top: 95%;
  left: 40%;
}
